class Particle
	constructor : ( @pos, @dir) ->
		@diam = 8
		rand_speed = random(5,6)
		@min_speed = 0.5 * rand_speed
		@max_speed = 2 * rand_speed
		@speed = 0
		@speed = random @min_speed, @max_speed
		@lerp_speed = random 0.05, 0.15
		@lerp_dir = random 0.01, 0.03
		@living = true
		@uv = createVector Math.random(), Math.random()
		@time = 0
		@color = lerpColor(color(Colors.yellow), color(Colors.green), @uv.x)
	update : (height, direction, delta) ->
		if @living
			target_speed = map height, 0, 1, @max_speed, @min_speed
			@speed = lerp @speed, target_speed, @lerp_speed

			@dir.lerp direction, @lerp_dir

			vel = @dir.copy()
			vel.mult @speed * delta
			@pos.add vel
